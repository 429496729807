<template>
    <div>
        <el-button size="small" type="primary" @click="handlePay">WechatPayDemo</el-button>
       <div style="padding-left:1% ;padding-top: 1%;" v-if="payData.qrcode">
        <div style="padding: 14px;">
                        <div><span>{{ payData.orderid }}</span></div>
                        <div><span>订单金额:￥{{ payData.paymount }}</span></div>
                    </div>
                    <img :src="payData.qrcode"
                        class="image">
       </div>
        <el-dialog :visible.sync="dialogVisible" style="width: 800px;">
            <!-- 支付表单信息 -->
            <el-form ref="form" :inline="true" :model="form" label-width="80px">
                <el-form-item label="订单号" prop="orderid">
                    <el-input placeholder="订单号" v-model="form.orderid" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="订单金额" prop="price">
                    <el-input placeholder="请输入订单金额" v-model="form.price"></el-input>
                </el-form-item>
                <el-form-item label="支付方式" prop="paytype">
                    <el-select v-model="form.paytype" placeholder="请选择">
                        <el-option label="二维码支付" :value="1"></el-option>
                        <el-option label="链接支付" :value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Cookie from 'js-cookie'
export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                orderid: '',
                price: '',
                paytype: 1
            },
            payData:{
               paymount:'',
               orderid:'',
               qrcode:''
            }
        };
    },
    methods: {
        cancel() {
            this.dialogVisible = false
        },
        handlePay() {
            //自动生成订单号
            const orderid = this.randomNumber()
            this.form.orderid = orderid
            this.dialogVisible = true
        },
        randomNumber() {
            const now = new Date()
            let month = now.getMonth() + 1
            let day = now.getDate()
            let hour = now.getHours()
            let minutes = now.getMinutes()
            let seconds = now.getSeconds()
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            hour = hour < 10 ? "0" + hour : hour;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            let orderCode = now.getFullYear().toString() + month.toString() + day + hour + minutes + seconds + (Math.round(Math.random() * 1000000)).toString();
            return orderCode;
        },
        submit() {
            console.log('submitform', this.form);
            this.postData();
            // const res = this.$http.post('wechatpay/pay', postData)
            // console.log('res',res);
            // if (res.code === 0) {
            //     this.$message.success('操作成功！')
            // } else {
            //     this.$message.error('操作失败')
            // }
            // this.getList()

            // //清空表单的数据
            // this.$refs.form.resetFields()
            // // 关闭弹窗
            // this.dialogVisible = false
        },
        async postData() {
            const { data: res } = await this.$http.post('wechatpay/pay', this.form)
            this.payData = res.data;
             //清空表单的数据
            this.$refs.form.resetFields()
            // 关闭弹窗
            this.dialogVisible = false
            console.log('res', res);
        }
    }
}
</script>
