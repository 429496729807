<template>
  <div style="width: 200px;">
      <el-upload class="upload-demo" 
      :on-preview="handlePreview" 
      :on-remove="handleRemove" 
      :on-success="handleSuccess"
      list-type="picture" 
      action="https://test.theshya.com/users/image" 
      :headers="headerObj"
      >
          <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="preUrl" alt="">
      </el-dialog>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
export default {
  data() {
      return {
          headerObj: {
              authorization: Cookie.get('token')
          },
          preUrl: '',
          dialogVisible: false,
          baseUrl:'https://test.theshya.com/'
      };
  },
  methods: {
      handlePreview(file) {
          this.preUrl = this.baseUrl+file.response.url
          console.log(1111,this.preUrl);
          this.dialogVisible = true
      },
      handleRemove(file) { },
      handleSuccess(file) { 
          console.log('success',file)
      }
  }
}
</script>
