<template>
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        :collapse="isCollapse" background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">
      <h3>{{isCollapse ? '后台':'Theshya后台管理系统'}}</h3>
        <el-menu-item  @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{item.label}}</span>
        </el-menu-item>
        <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
            <template slot="title">
                <i :class="`el-icon-${item.icon}`"></i>
                <span slot="title">{{item.label}}</span>
            </template>
            <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
                <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{subItem.label}}</el-menu-item>
            </el-menu-item-group>
        </el-submenu>
    </el-menu>
</template>

<script>
export default {
    data() {
        return {
            menuData: [
                {
                    path: '/',
                    name: 'home',
                    label: '首页',
                    icon: 's-home',
                    url: 'Home/Home'
                },
                {
                    path: '/image',
                    name: 'image',
                    label: '图片管理',
                    icon: 'camera',
                    url: 'ImageManage/ImageManage'
                },
                {
                    path: '/video',
                    name: 'video',
                    label: '视频管理',
                    icon: 'video-play',
                    url: 'VideoManage/VideoManage'
                },
                {
                    path: '/user',
                    name: 'user',
                    label: '用户管理',
                    icon: 'user',
                    url: 'UserManage/UserManage'
                },
                {
                    label: '其他',
                    icon: 'location',
                    children: [
                        {
                            path: '/bondstatistics',
                            name: 'bondstatistics',
                            label: '债券统计',
                            icon: 'setting',
                            url: 'Other/bondstatistics'
                        },
                        {
                            path: '/paytest',
                            name: 'pagetest',
                            label: '支付测试',
                            icon: 'setting',
                            url: 'Other/PageTwo'
                        },
                        {
                            path: '/uploaddemo',
                            name: 'uploaddemo',
                            label: '图片上传demo',
                            icon: 'setting',
                            url: 'Other/UploadDemo'
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        clickMenu(item){
            if(this.$route.path !== item.path && !(this.$route.path === '/home' && (item.path === '/'))){
                this.$router.push(item.path);
            }
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    computed:{
        //没有子菜单
        noChildren(){
            return this.menuData.filter(item => !item.children)
        },
        //有子菜单
        hasChildren(){
            return this.menuData.filter(item => item.children)
        },
        isCollapse(){
            return this.$store.state.tab.isCollapse
        }
    }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 1000px;
}
.el-menu{
    height: 100vh;
    border-right: none; //删除侧边栏和头部导航栏缝隙
    h3{
        color: #fff;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-weight: 400;
    }
}
</style>