<template>
    <div class="manage">
        <el-dialog :title="this.form.id ? '编辑记录' : '新增记录'" :visible.sync="dialogVisible" width="35%"
            :before-close="handleClose">
            <!-- 用户的表单信息 -->
            <el-form ref="form" :rules="rules" :inline="true" :model="form" label-width="80px">
                <el-form-item label="债券名称" prop="bond_name">
                    <el-input placeholder="请输入债券名称" v-model="form.bond_name"></el-input>
                </el-form-item>
                <el-form-item label="基金编号" prop="bond_number">
                    <el-input placeholder="请输入债券编号" v-model="form.bond_number"></el-input>
                </el-form-item>
                <el-form-item label="增长率" prop="rate">
                    <el-input placeholder="请输入增长率" v-model="form.rate"></el-input>
                </el-form-item>
                <el-form-item label="债券日期" prop="bond_date">
                    <el-date-picker v-model="form.bond_date" type="datetime" placeholder="选择日期时间" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
        <div class="manage-header">
            <el-button @click="handleAdd" type="primary">
                + 新增
            </el-button>
            <!-- form搜索区域 -->
            <el-form>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="common-tabel">
            <el-table stripe height="90%" :data="tableData" style="width: 100%">
                <el-table-column prop="bond_name" label="债券名称">
                </el-table-column>
                <el-table-column prop="bond_number" label="基金编号">
                </el-table-column>
                <!-- <el-table-column prop="sex" label="性别">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex == 1 ? '男' : '女' }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="bond_date" label="记录日期">
                </el-table-column>
                <el-table-column prop="rate" label="涨跌率(%)">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    style="text-align: center;"
                    class="center-pagination"
                    background
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :total="totalCount">
                </el-pagination>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                bond_name: '',
                bond_date: '',
                rate: '',
                bond_number: '',
                id: ''

            },
            rules: {
                name: [
                    { required: true, message: '请输入债券名称' }
                ],
                date: [
                    { required: true, message: '请输入增长日期' }
                ]
            },
            tableData: [],
            modalType: 0, // 0表示新增的弹窗， 1表示编辑
            totalCount: 0, //当前的总条数
            pageData: {
                page: 1,
                limit: 10
            },
        }
    },
    methods: {
        handleCurrentChange(){},
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        // 提交用户表单
        submit() {
            console.log('formmmmmm', this.form)
            const { bond_name, bond_date, bond_number, rate, id } = this.form
            const postData = {
                bond_name: bond_name,
                bond_date: bond_date,
                rate: rate,
                bond_number: bond_number,
                id: id
            }
            this.$refs.form.validate(async valid => {
                if (valid) {
                    // 后续对表单数据的处理
                    const { data: res } = await this.$http.post('bonds/addBond', postData)
                    if (res.code === 0) {
                        this.$message.success('操作成功！')
                    } else {
                        this.$message.error('操作失败')
                    }
                    this.getList()

                    //清空表单的数据
                    this.$refs.form.resetFields()
                    // 关闭弹窗
                    this.dialogVisible = false
                }
            })
        },
        // 弹窗关闭的时候
        handleClose() {
            this.$refs.form.resetFields()
            this.dialogVisible = false
        },
        cancel() {
            this.handleClose()
        },
        handleEdit(row) {
            console.log('rowwww', row)
            this.modalType = 1
            this.dialogVisible = true
            // 注意需要对当前行数据进行深拷贝，否则会出错
            this.form = JSON.parse(JSON.stringify(row))
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await this.$http.post('users/delUser', { id: row.id })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                // 重新获取列表的接口
                this.getList()

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleAdd() {
            this.modalType = 0
            this.dialogVisible = true
        },
        // 获取列表的数据
        async getList() {
            // 获取的列表的数据
            const { data: res } = await this.$http.get('bonds/getlist')
            // const data = res.data.map(item => {
            //     item.rate = item.rate + '%'
            //     return item
            // })
            this.tableData = res.data
        },
        // 选择页码的回调函数
        handlePage(val) {
            this.pageData.page = val
            this.getList()
        },
        // 列表的查询
        onSubmit() {
            this.getList()
        }
    },
    mounted() {
        this.getList()
    }
}
</script>
<style lang="less" scoped>
.manage {
    height: 90%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .common-tabel {
        position: relative;
        height: calc(100% - 62px);

        .pager {
            text-align: center;
        }
    }
}
</style>