<template>
    <div class="loginbody">
        <div class="logindata">
            <div class="logintext">
                <h2>Theshya</h2>
            </div>
            <div class="formdata">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="account">
                        <el-input
                                v-model="form.account"
                                clearable
                                placeholder="请输入账号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="psd">
                        <el-input
                                v-model="form.psd"
                                clearable
                                placeholder="请输入密码"
                                show-password
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="tool">
                <div>
                    <el-checkbox v-model="checked" @change="remenber"
                    >记住密码</el-checkbox
                    >
                </div>
                <div>
                    <span class="shou" @click="forgetpas">忘记密码？</span>
                </div>
            </div>
            <div class="butt">
                <el-button type="primary" @click.native.prevent="login()"
                >登录</el-button
                >
                <el-button class="shou" @click="register">注册</el-button>
            </div>
        </div>
        <div style="margin-top: 190px;text-align: center;font-size: 14px;color: #808695;">© 2023 theshya <a href="http://beian.miit.gov.cn/" target="_blank">鄂ICP备2023009351号-1</a></div>
    </div>
    
      
</template>

<script>
   import {login} from '../api';
   import Cookie from 'js-cookie';
    export default {
        name: "login",
        data() {
            return {
                form: {
                    psd: "123456",
                    account: "admin",
                },
                checked: false,
                rules: {
                    account: [
                        { required: true, message: "请输入用户名", trigger: "blur" },
                        { max: 11, message: "不能大于11个字符", trigger: "blur" },
                    ],
                    psd: [
                        { required: true, message: "请输入密码", trigger: "blur" },
                        { max: 10, message: "不能大于10个字符", trigger: "blur" },
                    ],
                },
            };
        },
        mounted() {
            if(localStorage.getItem("news")){
                this.form=JSON.parse(localStorage.getItem("news"))
                this.checked=true
            }
        },
        methods: {
            login() {
                this.$refs.form.validate(async valid => {
                    if(!valid)   return
                    login(this.form).then(res=>{
                        const data = res.data
                        if(data.code !== 0)  return this.$message.error('登录失败')
                        const token = data.data
                        this.$message.success('登录成功')
                        Cookie.set('token',token)
                        this.$router.push('/home')
                       
                    })
                    //const data = await this.$http.post('users/login',this.form);
                    //console.log('data',data);return;
                    // if(res.code !== 0)  return this.$message.error('登录失败')
                    // console.log(res) 
                    // this.$message.success('登录成功')
                    // window.sessionStorage.setItem('token',res.data);
                    // window.localStorage.setItem('account',this.form.account);
                    // this.$router.push('home')


                });
            },
            remenber(data){
                this.checked=data2
                if(this.checked){
                    localStorage.setItem("news",JSON.stringify(this.form))
                }else{
                    localStorage.removeItem("news")
                }
            },
            forgetpas() {
                this.$message({
                    type:"info",
                    message:"功能尚未开发额😥",
                    showClose:true
                })
            },
            register() {},
        },
    };
</script>

<style lang="less" scoped>
    .loginbody {
        width: 100%;
        height: 100%;
        min-width: 1000px;
        background-image: url("../assets/images/loginbg.jpg");
        background-size: 100% 100%;
        background-position: center center;
        overflow: auto;
        background-repeat: no-repeat;
        position: fixed;
        line-height: 100%;
        padding-top: 150px;
    }

    .logintext {
        margin-bottom: 20px;
        line-height: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: bolder;
        color: white;
        text-shadow: 2px 2px 4px #000000;
    }

    .logindata {
        width: 300px;
        height: 300px;
        transform: translate(-50%);
        margin-left: 50%;
    }

    .tool {
        display: flex;
        justify-content: space-between;
        color: #606266;
    }

    .butt {
        margin-top: 10px;
        text-align: center;
    }

    .shou {
        cursor: pointer;
        color: #606266;
    }
    .icp {
        color: #808695;
      font-size: 14px;
    }


</style>

