// 配置路由相关的信息
// 配置路由相关的信息
import Router from 'vue-router'
import Vue from 'vue'
import Home from "../views/Home.vue";
import User from "../views/User.vue";
import Main from "../views/Main.vue";
import Video from "../views/Video.vue";
import BondStatistics from "../views/BondStatistics.vue";
import PageTwo from "../views/PageTwo.vue";
import UploadDemo from "../views/UploadDemo.vue";
import Login from "../views/Login.vue";
import Image from "../views/Image.vue"

// 通过Vue.use(插件)，安装插件
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
        {
            path:'/',
            component:Main,
            redirect:'/home', //重定向
            children:[
                {path:'home',name:'home',component:Home},
                {path:'user',name:'user',component:User},
                {path:'image',name:'image',component:Image},
                {path:'video',name:'video',component:Video},
                {path:'bondstatistics',name:'bondstatistics',component:BondStatistics},
                {path:'paytest',name:'paytest',component:PageTwo},
                {path:'uploaddemo',name:'uploaddemo',component:UploadDemo},
            ]
        },
        {
            path:'/login',
            name:'login',
            component:Login
        }
        
    ]

const router = new Router({
    // 配置路由和组件之间的应用关系
    routes
})

export default  router