import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import './api/mock'
import Cookie from 'js-cookie'
import axios from 'axios'
//引入自定义的样式文件
import './theme/index.css';
Vue.config.productionTip = false

import App from './App.vue'
axios.defaults.baseURL = 'https://test.theshya.com/'
//axios 请求拦截
axios.interceptors.request.use(config => {
  //为请求头对象，添加Token验证的Authorization字段
config.headers.Authorization = Cookie.get('token')
  //在最后必须return config
  return config
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  const code = response.data.code
  //token校验失败跳转至登录页
  if([201,202].includes(code)){
    Cookie.remove('token')
    router.push('/login')
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});
Vue.prototype.$http = axios
Vue.prototype.$baseUrl = 'https://test.theshya.com/'
//添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 判断token存不存在
  const token = Cookie.get('token')
  //如果token不存在，说明当前用户未登录，应该调整至登录页
  if (!token && to.name !== 'login') {
    next({
      name: 'login'
    })
  } else if (token && to.name === 'login') { //token存在，说明用户登录，此时跳转至首页
    next({ name: 'home' })
  } else {
    next()
  }

})
//图片上传组件
import UploadImg from "@/components/UploadImage.vue";
Vue.component('UploadImg', UploadImg)

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
