import http from '../utils/request.js'

//请求首页数据

export const getData = ()=>{
    return http.get('/home/getData')
}
//请求登录
export const login = (params)=>{
    return http.post('https://test.theshya.com/users/login',params)
}
//请求用户列表
export const getUser = ()=>{
    return http.post('/users/getUserlist')
}