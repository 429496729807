<template>
    <div>
        <el-form>
            <el-form-item label="上传图片">
        <UploadImg :limit="1" :disabled="false" :file-list="fileList" @getUrl="getUrl($event, '自己需要携带的参数')"
            @delUrl="delUrl($event, '自己需要携带的参数')"  />
    </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fileList: []
        };
    },
    methods: {
        getUrl(getUrl) {
            console.log(getUrl)
        },
        delUrl(getUrl) {
            console.log(getUrl)
        }
    }
}
</script>
