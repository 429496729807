<template>
    <div id="app">
        <!-- 路由占位符 -->
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "app"
    }
</script>

<style lang="less" >
    html,body,h3,p{
        margin: 0;
        padding: 0;
    }
</style>