<template>
    <div class="header-container">
        <div class="l-content">
            <el-button @click="handleMenu" icon="el-icon-menu" size="mini"></el-button>
            <!-- 面包屑 -->
            <span class="text">首页</span>
        </div>
        <div class="r-content">
            <el-dropdown @command="handleClick">
                <span class="el-dropdown-link">
                    <img class="user" src="../assets/images/head1.png" alt="">
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>个人中心</el-dropdown-item>
                    <el-dropdown-item command="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { ColorPicker } from 'element-ui';
import Cookie from 'js-cookie';
export default {
    name: "Mall",
    data(){
        return {}
    },
    methods:{
        handleMenu(){
            this.$store.commit('collapseMenu');
        },
        handleClick(command){
            if(command === 'logout'){
                //清除cookie中的token
                Cookie.remove('token')
                //跳转至登录页面
                this.$router.push('/login')
            }
        }
    }

}
</script>

<style lang="less" scoped>
.header-container {
    padding: 0 20px;
    background-color: #333;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
    }
    .r-content{
        .user{
            height: 40px;
            width: 40px;
            border-radius: 50%;

        }
    }
}
</style>